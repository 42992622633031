import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

interface Nodes {
  id: string
  frontmatter: {
    title: string
    slug: string
    description: string
  }
}

export const useArticles = (): Nodes[] => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          nodes {
            id
            frontmatter {
              title
              description
              slug
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark.nodes
}

const Articles = () => {
  const nodes = useArticles()
  return (
    <Layout>
      <Helmet>
        <title>Articles</title>
      </Helmet>
      <h1>All Articles</h1>
      <ul>
        {nodes.map(node => {
          return (
            <li key={node.id} className="pb-5">
              <Link
                to={node.frontmatter.slug}
              >
                <h2>
                  {node.frontmatter.title}
                </h2>
                <blockquote>{node.frontmatter.description}</blockquote>
              </Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}
export default Articles
